import React from "react";
import Header from "../partials/header"
import Footer from "../partials/footer"
import {Link} from 'gatsby';
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const Aboutus = () => {
    return (
        <main>
            <Header/>
            <AruzySeo title={"Terms & Conditions"}/>
            <div className="App">
                <div className="container changeheading pt-60 mb-30">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="cat-header text-center">
                                <HeaderTitle title={'Terms & Conditions'}/>
                            </div>
                            <div className="col-md-12 tcpage">
                                <h3> 1. INTRODUCTION</h3>
                                <ul>
                                    <li><span>1.1</span><p>
                                        This page (together with the documents referred to on it)
                                        tells you the terms and conditions on which
                                        we supply your ARUZY <Link to='/package'>Membership</Link>.
                                        Further details regarding the Membership can be found on our
                                        website <Link to="/">www.aruzy.co.uk</Link>.
                                    </p>
                                    </li>
                                    <li><span>1.2</span><p>Please read these terms carefully before
                                        ordering a membership from our Site. These terms tell you
                                        who we are, how we will provide services to you, how you and
                                        we may change or end the contract, what to do if there is a
                                        problem and other important information. If you think that
                                        there is a mistake in these terms or that they require any
                                        changes, please contact us to discuss.
                                    </p>
                                    </li>
                                    <li><span>1.3</span><p>We will use your personal data in the
                                        provision of your Membership to you, in accordance with our
                                        privacy policy. Please ensure that you read and understand
                                        our
                                        <Link to="/privacy-policy">Privacy Policy</Link> (you can
                                        access our privacy policy on our site)</p>
                                    </li>
                                    <li><span>1.4 </span><p>If you refuse to accept these terms and
                                        conditions, we will not be able to process and activate your
                                        Membership. </p>
                                    </li>
                                    <li><span>1.5 </span><p>We reserve the right to amend these
                                        terms
                                        and conditions at any time by giving you notice and/or by
                                        posting the amended terms and conditions on our Site. </p>
                                    </li>
                                </ul>
                                <h3>2. ABOUT US</h3>
                                <ul>
                                    <li><span> 2.1 </span><p>We are Say Lifestyle Ltd a company
                                        registered in England and Wales, and trading under the
                                        business name ARUZY. Our company registration number is
                                        13278129 and our registered office is at Suite 9b, Turner
                                        Business Centre, Greengate, Middleton, Manchester, England
                                        M24 1RU. </p>
                                    </li>
                                    <li><span> 2.2</span><p>You can contact us by writing to us at
                                        <a href="mailto:hello@aruzy.co.uk">Hello@aruzy.co.uk</a> .You can also contact us via the
                                        application located in the “contact us” section on our
                                        Site. </p>
                                    </li>
                                    <li><span> 2.3 </span><p>If we have to contact you, we will do
                                        so in writing to you at the email address you provided to us
                                        in your order.
                                        Please note, it is your responsibility to advise us of a new
                                        email address so we can keep your details up to date. </p>
                                    </li>
                                    <li><span> 2.4</span><p>When we use the words "writing" or
                                        "written" in these terms, this includes emails. </p>
                                    </li>
                                    <li><span> 2.5 </span><p>By placing an order for a membership
                                        through our Site, you warrant that you are legally capable
                                        of entering into binding contracts and you are at least 16
                                        years old.
                                        We can only deal with the member about a membership so any
                                        contact with us regarding the Membership should only be made
                                        by the member. </p>
                                    </li>
                                    <li><span> 2.6</span>The contract between us will only be formed
                                        when you accept the terms and conditions of the Membership.
                                        This acceptance confirms you agree to go ahead with the
                                        service.
                                    </li>
                                </ul>
                                <h3>3. TERM</h3>
                                <ul>
                                    <li><span> 3.1</span><p>Our acceptance of your Membership will
                                        take place when you make successful payment of the fee via
                                        the application, at which point a contract will come into
                                        existence between you and us. </p>
                                    </li>
                                    <li><span>3.2</span><p>Your Membership is continuous and is
                                        renewed automatically every month at the end of each monthly
                                        Membership period. Your renewal date is the expiry of 30
                                        days of the date your Membership is accepted and every 30
                                        days thereafter. If you do not wish to renew your Membership
                                        you should contact us in accordance with clause 4.2. </p>
                                    </li>
                                </ul>
                                <h3> 4. YOUR CONSUMER RIGHTS</h3>
                                <ul>
                                    <li>
                                        <span>   4.1 </span><p>There is a 14-day cooling-off period,
                                        which commences the day after you agree to these terms and
                                        conditions. This does not apply to subsequent renewals. From
                                        time-to-time certain promotions may have different
                                        requirements regarding the cancellation period. \
                                    </p>
                                    </li>
                                    <li><span> 4.2</span><p>To cancel your membership please email
                                        <a href="mailto:hello@aruzy.co.uk">Hello@aruzy.co.uk</a>, with ‘cancel membership’ as the subject,
                                        at least 3 working days before the end of your current
                                        membership period.
                                        Upon cancellation you will receive a cancellation email,
                                        which you should keep for your own personal records. </p>
                                    </li>
                                    <li><span>  4.3</span><p>If you cancel your Membership within
                                        the cooling-off period, you will be entitled to a refund of
                                        your Membership, less a pro-rata charge for the period of
                                        cover. Subject to any other statutory rights you may have,
                                        we do not provide refunds for any cancellations after the
                                        expiry of the cooling-off period. </p></li>
                                </ul>
                                <h3> 5. PRICE AND PAYMENT</h3>
                                <ul>
                                    <li>
                                        <span>  5.1 </span><p>The price of the Membership will be as
                                        quoted on our Site from time to time, except in the case of
                                        obvious error. </p></li>
                                    <li>
                                        <span> 5.2</span><p> Prices include VAT.</p></li>
                                    <li>
                                        <span>  5.3 </span><p>Prices are liable to change at any
                                        time, but you will be notified, by email, at least 1 month
                                        prior to any changes coming into effect. </p></li>
                                    <li>
                                        <span> 5.4</span><p>Payment must be by credit or debit card,
                                        or by such other method as we may agree from time to time.
                                        We will charge your credit or debit card when your order is
                                        placed. </p></li>
                                </ul>
                                <h3> 6. YOUR MEMBERSHIP AND USE AT PARTICIPATING DISCOUNT AND OFFER
                                    PROVIDERS</h3>
                                <ul>
                                    <li>
                                        <span> 6.1</span><p>Discounts and offers advertised on our
                                        Site are only available to members who present a valid
                                        coupon/QR code. Such offers are not available in conjunction
                                        with any other offers that participating discount and offer
                                        providers may be running. </p></li>
                                    <li>
                                        <span> 6.2 </span><p>We will use our reasonable endeavours
                                        to update our Site to show the particulars of participating
                                        discount and offer providers and the terms of their
                                        availability. Participating discount and offer providers
                                        may, however, be entitled to withdraw or to change the terms
                                        and conditions of their availability after you have become a
                                        member and we shall have no liability for any such
                                        withdrawals or changes in terms and conditions or
                                        availability. </p></li>
                                    <li>
                                        <span>6.3</span><p>Please check our Site for any exclusions
                                        to any discounts and offers by participating providers, and
                                        also refer to their individual pages as other exclusions may
                                        apply. </p></li>
                                    <li>
                                        <span> 6.4</span><p>Members will have the benefit of any
                                        additional discount and offer providers which may join at a
                                        later date and any increase in availability of participating
                                        discount and offer providers. </p>
                                    </li>
                                    <li>
                                        <span>  6.5 </span><p>When you redeem an offer through our
                                        Site you will receive a coupon/QR code which you will be
                                        able to use with the participating discount and offer
                                        providers. If you do not receive the coupon/QR code, please
                                        let us know by emailing us at <a href="mailto:hello@aruzy.co.uk">Hello@aruzy.co.uk</a> . </p></li>
                                </ul>
                                <h3> 7. OUR LIABILITY</h3>
                                <ul>
                                    <li>
                                        <span>   7.1 </span><p>Subject to clause 7.3, if we fail to
                                        comply with these terms and conditions, our liability to you
                                        will be limited to the membership fee. </p></li>
                                    <li style={{display: 'block'}}>
                                        <span> 7.2</span> Subject to clause 7.3, we will not be
                                        liable for any losses that result from our failure to comply
                                        with these terms and conditions that fall into the following
                                        categories:
                                        <ul>
                                            <li>
                                                <span>   7.2.1</span><p> Loss of income or
                                                revenue;</p></li>
                                            <li>
                                                <span>   7.2.2</span><p> Loss of business;</p></li>
                                            <li>
                                                <span>  7.2.3</span><p> Loss of profits; or</p></li>
                                            <li>
                                                <span>  7.2.4</span><p> Loss of anticipated
                                                savings.</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li style={{display: 'block'}}>
                                        <span>   7.3 </span> Nothing in this agreement excludes
                                        or
                                        limits our liability for:
                                        <ul>
                                            <li>
                                                <span>  7.3.1</span><p> Death or personal injury
                                                caused by
                                                our
                                                negligence;</p></li>
                                            <li>
                                                <span> 7.3.2</span><p> Fraud or fraudulent
                                                misrepresentation;</p></li>
                                            <li>
                                                <span>  7.3.3</span><p> Any breach of the
                                                obligations
                                                implied by
                                                section 12 of the
                                                Sale of Goods Act 1979 or section 2 of the Supply of
                                                Goods
                                                and
                                                Services Act 1982; or</p></li>
                                            <li>
                                                <span>   7.3.4 </span><p> Any other matter for which
                                                it would be illegal for us to exclude or attempt to
                                                exclude our liability. </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>   7.4 </span><p>When you receive services and/or
                                        purchase goods or services from any participating discount
                                        and offer providers, any losses or liability arising out of,
                                        or in connection with, such goods, services and/or food
                                        shall be the relevant participating discount and offer
                                        provider's liability. We accept no liability for any bad or
                                        cancelled experiences, faulty goods or bad food or cancelled
                                        reservations at any of the participating discount and offer
                                        providers. We will not become involved in any dispute
                                        between you and any discount and offer providers. </p></li>
                                    <li>
                                        <span>  7.5</span><p>We do not give any warranty for any
                                        goods or services accessed through, or displayed on, our
                                        Site. </p></li>
                                </ul>
                                <h3> 8. WRITTEN COMMUNICATION</h3>
                                <ul>
                                    <li><p>Applicable laws require that some of the information or
                                        communications we send to you should be in writing. When
                                        using our Site, you accept that communication with us will
                                        be mainly electronic. We will contact you by e-mail or
                                        provide you with information by posting notices on our Site.
                                        For contractual purposes, you agree to this electronic means
                                        of communication and you acknowledge that all contracts,
                                        notices, information and other communications that we
                                        provide to you electronically comply with any legal
                                        requirement that such communications be in writing. This
                                        condition does not affect your statutory rights. </p></li>
                                </ul>
                                <h3> 9. NOTICES</h3>
                                <ul>
                                    <li><p>All notices given by you to us must be given to
                                        <a href="mailto:hello@aruzy.co.uk">Hello@aruzy.co.uk</a> . We may give notice to you at either the
                                        e-mail or postal address you provide to us when placing your
                                        order for membership, or in any of the ways specified in
                                        clause 8 above. Notice will be deemed received and properly
                                        served immediately when posted on our website, 24 hours
                                        after an e-mail is sent, or three days after the date of
                                        posting any letter. In proving the service of any notice, it
                                        will be sufficient to prove, in the case of a letter, that
                                        such letter was properly addressed, stamped and placed in
                                        the post and, in the case of an e-mail, that such e-mail was
                                        sent to the specified e-mail address of the addressee. </p>
                                    </li>
                                </ul>
                                <h3> 10. HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
                                <ul>
                                    <li><p>We will only use your personal information as set out in
                                        our privacy policy (you can access our privacy policy on our
                                        site). </p></li>
                                </ul>
                                <h3> 11. WAIVER</h3>
                                <ul>
                                    <li><p>Failure by us to enforce any of these terms and
                                        conditions will not prevent us from subsequently relying on,
                                        or enforcing them. </p></li>
                                </ul>
                                <h3>12. SEVERABILITY</h3>
                                <ul>
                                    <li><p>If any court or competent authority decides that any of
                                        the provisions of these terms and conditions are invalid,
                                        unlawful or unenforceable to any extent, the term will, to
                                        that extent only, be severed from the remaining terms, which
                                        will continue to be valid to the fullest extent permitted by
                                        law. </p></li>
                                </ul>
                                <h3>13. THIRD PARTY RIGHTS</h3>
                                <ul>
                                    <li><p>A person who is not party to these terms and conditions
                                        shall not have any rights under or in connection with it
                                        under the Contracts (Rights of Third Parties) Act 1999. </p>
                                    </li>
                                </ul>
                                <h3>14. ENTIRE AGREEMENT</h3>
                                <ul>
                                    <li><p>These terms and conditions and any document expressly
                                        referred to in them constitute the whole agreement between
                                        us and supersede all previous discussions, correspondence,
                                        negotiations, previous arrangement, understanding or
                                        agreement between us relating to the subject matter of these
                                        terms and conditions. We acknowledge that, in entering into
                                        these terms and conditions, neither of us relies on, or will
                                        have any remedies in respect of, any representation or
                                        warranty (whether made innocently or negligently) that is
                                        not set out in these terms and conditions or the documents
                                        referred to in them. Nothing in this clause limits or
                                        excludes any liability for fraud. </p></li>
                                </ul>
                                <h3>15. LAW AND JURISDICTION</h3>
                                <ul>
                                    <li><p>This agreement shall be interpreted in accordance with
                                        the English law and subject to the exclusive jurisdiction of
                                        the English Courts. </p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    );
};

export default Aboutus;